<template lang="pug">
  .manager-organizations-show
    b-row.mb-5
      b-col
        organizations-panel(ref='organizationsPanel')
    b-row.mb-5
      b-col
        router-view(@organization-changed='reloadOrganizationsPanel')
</template>

<script>
import OrganizationsPanel from '@views/container/manager/organizations/Panel'

export default {
  name: 'manager-organizations-show',
  components: {
    OrganizationsPanel,
  },
  methods: {
    reloadOrganizationsPanel() {
      this.$refs.organizationsPanel.panelReload()
    },
  },
}
</script>
